import { useState } from "react";

const TAG_OPTIONS = [
  "worked_out",
  "had_sex",
  "drank_alcohol",
  "total_calories",
  "bike_ride",
  "played_game",
  "hair_cut",
  "watched_movie",
  "watched_show",
  "saw_friend",
  "ate_food",
  "sick_rating",
  "sick_symptom",
  "smoked",
  "feeling",
];

export const Tag = (props) => {
  const [name, setName] = useState("worked_out");
  const [value, setValue] = useState("");

  return (
    <div className="row tags-row">
      <div className="col-4">
        <select
          onChange={(e) => setName(e.target.value)}
          value={name}
          className="form-control"
          placeholder="Tag Name"
        >
          {TAG_OPTIONS.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div className="col-4">
        <input
          type="text"
          onChange={(e) => setValue(e.target.value)}
          value={value}
          className="form-control"
          placeholder="Tag Value"
        ></input>
      </div>
      <div className="col-4">
        <button
          className="btn btn-info"
          onClick={() => {
            props.addTag(name, value);
            setName("");
            setValue("");
          }}
        >
          Add Tag
        </button>
      </div>
    </div>
  );
};
