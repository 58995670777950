import * as React from "react";
import { Rating } from "./components/rating";
import { v4 } from "uuid";
import { Tag } from "./components/tag";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 1,
      journal: "",
      tags: [],
      dayFor: undefined,
      lastSubmit: new Date(),
    };
  }

  componentDidMount() {
    fetch(`https://sljbeuyrgj.execute-api.us-east-1.amazonaws.com/journal`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.sort((a, b) => b.timestamp - a.timestamp);
        this.setState({ lastSubmit: new Date(data[0].timestamp) });
      });
  }

  async submit() {
    const { rating, journal, tags, dayFor } = this.state;
    await fetch(
      `https://sljbeuyrgj.execute-api.us-east-1.amazonaws.com/writeJournal`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ rating, journal, id: v4(), tags, dayFor }),
      }
    );
    this.setState({ rating: 3, journal: "", tags: [] });
  }

  setRating(rating) {
    this.setState({ rating });
  }

  setJournal(event) {
    this.setState({ journal: event.target.value });
  }

  addTag(name, value) {
    const newTags = [...this.state.tags, { name, value, id: v4() }];
    this.setState({ tags: newTags });
  }

  setDate(event) {
    this.setState({ dayFor: event.target.value });
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="header-text">
              Last Submit Date: {`${this.state.lastSubmit.toDateString()}`}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8">
            <Rating
              onChange={this.setRating.bind(this)}
              rating={this.state.rating}
            />
          </div>
          <div className="col-2"></div>
        </div>
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8">
            <textarea
              className="main-editor"
              onChange={this.setJournal.bind(this)}
              value={this.state.journal}
            ></textarea>
          </div>
          <div className="col-2"></div>
        </div>
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8">
            <Tag
              addTag={(name, value) => this.addTag(name, value)}
              tags={this.state.tags}
            />
          </div>
          <div className="col-2"></div>
        </div>
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8">
            {this.state.tags.map((tag) => (
              <div key={tag.value}>
                {tag.name} - {tag.value}
              </div>
            ))}
          </div>
          <div className="col-2"></div>
        </div>
        <div className="row add-padding">
          <div className="col-2"></div>
          <div className="col-4">
            Change Day:{" "}
            <input
              type="date"
              className="form-control"
              onChange={(e) => this.setDate(e)}
              value={this.state.dayFor}
            />
          </div>
          <div className="col-2"></div>
        </div>
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8">
            <button
              className="btn btn-success btn-block submit-button"
              onClick={this.submit.bind(this)}
            >
              Submit Entry
            </button>
          </div>
          <div className="col-2"></div>
        </div>
      </div>
    );
  }
}

export default App;
