import { Component } from "react";

export class Rating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ratings: [
        {
          number: 1,
          text: 1,
        },
        {
          number: 2,
          text: 2,
        },
        {
          number: 3,
          text: 3,
        },
        {
          number: 4,
          text: 4,
        },
        {
          number: 5,
          text: 5,
        },
      ],
      selected: -1,
    };
  }

  clickRating(ratingNumber) {
    this.setState({ selected: ratingNumber });
    this.props.onChange(ratingNumber);
  }

  render() {
    return (
      <div className="row rating-row">
        {this.state.ratings.map((rating) => {
          return (
            <div
              key={rating.number}
              onClick={() => this.clickRating(rating.number)}
              className={`col-2 rating ${
                this.state.selected === rating.number ? "selected" : ""
              }`}
            >
              {rating.text}
            </div>
          );
        })}
      </div>
    );
  }
}
